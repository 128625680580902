<template>
  <div>
    <div v-if="edit" class="edit-div">
      <img :src="agencyPic" v-if="agencyPic != ''" />
      <label
        for="fileUpload"
        class="browse-button"
        v-show="$store.getters.userType != 'contactCenterAgent'"
        :style="{
          backgroundColor: $store.getters.color.color1,
          color: $store.getters.color.fontColor,
          borderColor: $store.getters.color.color1,
        }"
        >{{ $t("label.agencyPic") }}</label
      >
      <input
        type="file"
        accept="image/png, image/jpeg"
        name="fileUpload"
        id="fileUpload"
        @change="uploadAgencyPic"
      />
      <br />
      <label for="name">{{ $t("label.name") }}</label>
      <input type="text" id="name" v-model="name" required />
      <br />
      <label for="email">{{ $t("label.email") }}</label>
      <input type="email" id="email" v-model="email" />
      <br />
      <label for="emergencyNumber">{{ $t("label.emergencyNumber") }}</label>
      <input type="text" id="emergencyNumber" v-model="emergencyNumber" />
      <br />
      <label for="phone">{{ $t("label.phone") }}</label>
      <input type="text" id="phone" v-model="phone" />
      <br />
      <br />
      <label for="website">{{ $t("label.website") }}</label>
      <input type="text" id="website" v-model="website" />
      <br />
      <br />
      <!--       <mini-search
        :elements="
          employees.map((employee) => {
            return {
              label:
                capitalizeFirstLetter(employee.name) +
                ' ' +
                capitalizeFirstLetter(employee.surname),
              destination: '/employee/remove/' + employee._id,
            };
          })
        "
        :title="$t('agency.deleteEmployee')"
        :color="{
          background: $store.getters.color.color2,
          font: $store.getters.color.fontColor,
          linkColor: $store.getters.color.color3,
        }"
        :minWidth="screenMinWidth(1000)"
        :maxWidth="'1000px'"
        :style="{ lineHeight: '20px' }"
      ></mini-search> -->
      <br />
      <div class="color-grid">
        <span>{{ $t("label.color", { number: 1 }) }}</span>
        <verte
          v-model="color.color1"
          picker="square"
          model="hex"
          :rgbSliders="true"
        ></verte>
        <span>{{ $t("label.color", { number: 2 }) }}</span>
        <verte
          v-model="color.color2"
          picker="square"
          model="hex"
          :rgbSliders="true"
        ></verte>
        <span>{{ $t("label.color", { number: 3 }) }}</span>
        <verte
          v-model="color.color3"
          picker="square"
          model="hex"
          :rgbSliders="true"
        ></verte>
        <span>{{ $t("label.fontColor") }}</span>
        <verte
          v-model="color.fontColor"
          picker="square"
          model="hex"
          :rgbSliders="true"
        ></verte>
      </div>

      <br />
      <button
        id="confirmation-button"
        @click="submitEdit"
        :disabled="checkForm"
        class="save-button"
      >
        {{ $t("buttons.save") }}
      </button>
      <button id="back-button" @click="getBack" class="back-button">
        {{ $t("buttons.back") }}
      </button>
    </div>
    <div v-if="!edit" class="display">
      <img :src="agencyPic" v-if="agencyPic != ''" />
      <br />
      <h1>{{ name }}</h1>
      <button
        class="edit-button"
        @click="toogleEdit"
        :style="{
          borderColor: $store.getters.color.color1,
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
        }"
        v-if="this.$store.getters.userId == this.administrator._id"
      >
        {{ $t("buttons.edit") }}
      </button>
      <div
        class="contract-not-signed TA"
        v-if="
          !contractSigned &&
          this.$store.getters.userType == 'travelAssistEmployee'
        "
      >
        <p v-if="!contractInformationFilled">
          Renseigner les informations de l'agence
        </p>
        <p
          v-if="
            this.$store.getters.userType == 'travelAssistEmployee' &&
            contractInformationFilled &&
            TAValidation
          "
        >
          Lien de validation du contrat :
        </p>
        <p
          v-if="
            this.$store.getters.userType == 'travelAssistEmployee' &&
            contractInformationFilled &&
            TAValidation
          "
        >
          https://app.geniustravel.io/#/contract/{{
            this.$route.params.id +
            "?pass=" +
            this.contractPassword +
            "&agency=true"
          }}
        </p>
        <a
          :href="
            '/#/preagency/validation/' + this.$route.params.id + '?agency=true'
          "
          v-if="!contractInformationFilled && !TAValidation"
        >
          Remplir
        </a>
      </div>
      <p>{{ email }}</p>
      <p>
        {{
          $t("agency.partnerDate", {
            date: $d(new Date(this.contractStartDate), "short"),
          })
        }}
      </p>
      <router-link
        class="download-button"
        :to="
          '/download/contract/' +
          this.$route.params.id +
          '?pass=' +
          this.contractPassword
        "
        target="_top"
        style="
          background-color: rgb(9, 201, 111);
          color: white;
          border: none;
          border-radius: 10px;
          margin: 10px auto;
          font-weight: bold;
          padding: 5px 20px;
          font-size: 15px;
        "
        v-if="
          (this.$store.getters.userId == this.administrator._id &&
            this.contractSigned) ||
          (this.$store.getters.userType == 'travelAssistEmployee' &&
            (this.contractSigned || this.contractInformationFilled))
        "
        >{{ $t("buttons.downloadContract") }}</router-link
      >
      <p>
        <span>{{ emergencyNumber }}</span>
        <span v-if="phone != '' && phone != emergencyNumber">
          {{ " / " + phone }}
        </span>
      </p>
      <a
        v-if="website != ''"
        :href="website"
        :style="{ color: $store.getters.color.color3 }"
        >{{ website }}</a
      >
      <!-- <div class="add-app-agency">
        <button
          type="button"
          name="button"
          v-show="this.$store.getters.userId == this.administrator._id"
          :style="{ backgroundColor: '#179cde' }"
          @click="pairedApp.telegram ? checkTelegram() : addTelegram()"
        >
          {{
            pairedApp.telegram
              ? this.$t("agency.checkApp", { app: "Telegram" })
              : this.$t("agency.connectApp", { app: "Telegram" })
          }}
        </button>
      </div>
      <div class="affiliation-div">
        <router-link
          :to="
            affiliation
              ? {
                  name: 'affiliationDisplay',
                  params: { id: affiliation, tourAgency: $route.params.id },
                }
              : {
                  name: 'affiliationForm',
                  params: { tourAgency: $route.params.id },
                }
          "
          :style="{ color: $store.getters.color.color3 }"
          v-if="this.$store.getters.userId == this.administrator._id"
        >
          {{
            affiliation
              ? "Affiliation Information"
              : "Generate your affiliation link"
          }}
        </router-link>
      </div> -->
      <hr />
      <br />
      {{ $t("agency.administrator") }}:
      <span
        v-if="
          (this.$store.getters.userType == 'tourAgencyEmployee' ||
            this.$store.getters.userType == 'travelAssistEmployee') &&
          this.administrator
        "
      >
        {{
          capitalizeFirstLetter(this.administrator.name) +
          " " +
          capitalizeFirstLetter(this.administrator.surname)
        }}
      </span>
      <br />
      <div>
        <div
          v-if="
            employees != null &&
            (this.$store.getters.userType == 'tourAgencyEmployee' ||
              this.$store.getters.userType == 'travelAssistEmployee')
          "
        >
          <br />
          <mini-search
            :elements="
              employees.map((employee) => {
                return {
                  label:
                    capitalizeFirstLetter(employee.name) +
                    ' ' +
                    capitalizeFirstLetter(employee.surname),
                  destination: '',
                };
              })
            "
            :title="$t('agency.employees')"
            :color="{
              background: $store.getters.color.color2,
              font: $store.getters.color.fontColor,
              linkColor: $store.getters.color.color3,
            }"
            :minWidth="screenMinWidth(1000)"
          ></mini-search>
          <router-link
            :to="{
              name: 'employeeForm',
              params: { agency: $route.params.id, newAgency: false },
            }"
            class="generate-form-link"
            :style="{
              backgroundColor: $store.getters.color.color2,
              color: $store.getters.color.fontColor,
            }"
            v-if="this.$store.getters.userId == this.administrator._id"
            >{{ $t("buttons.add") }}</router-link
          >
        </div>
        <br />
        <mini-search
          :elements="buildClientsSearch"
          :title="$t('agency.clients')"
          :color="{
            background: $store.getters.color.color2,
            font: $store.getters.color.fontColor,
            linkColor: $store.getters.color.color3,
          }"
          :minWidth="screenMinWidth(1000)"
        ></mini-search>
        <router-link
          :to="{
            name: 'clientForm',
            params: { tourAgency: $route.params.id },
          }"
          class="generate-form-link"
          :style="{
            backgroundColor: $store.getters.color.color2,
            color: $store.getters.color.fontColor,
          }"
          v-if="
            this.$store.getters.userId == this.administrator._id ||
            (this.employees &&
              this.employees
                .map((employee) => employee._id)
                .includes(this.$store.getters.userId))
          "
          >{{ $t("buttons.new") }}</router-link
        >
      </div>
      <br />
      <router-link
        :style="{ color: $store.getters.color.color3 }"
        :to="{
          name: 'travelForm',
          params: {
            tourAgency: $route.params.id,
            agencyClients: clients,
            client: {},
          },
        }"
        v-if="
          this.$store.getters.userId == this.administrator._id ||
          (this.employees &&
            this.employees
              .map((employee) => employee._id)
              .includes(this.$store.getters.userId))
        "
        >{{ $t("agency.travel") }}</router-link
      >
      <br />
      <br />
      <mini-search
        :elements="
          travels.map((travel) => {
            return {
              label: travel.name,
              destination: '/travel/' + travel._id,
            };
          })
        "
        :title="$t('agency.travels')"
        :color="{
          background: $store.getters.color.color2,
          font: $store.getters.color.fontColor,
          linkColor: $store.getters.color.color3,
        }"
        :minWidth="screenMinWidth(1000)"
        class="travel-mini-search"
        v-if="
          this.travels &&
          (this.$store.getters.userType === 'travelAssistEmployee' ||
            this.$store.getters.userId == this.administrator._id ||
            this.employees
              .map((employee) => employee._id)
              .includes(this.$store.getters.userId))
        "
      ></mini-search>
      <br />
      <mini-search
        :elements="
          travels
            .filter((travel) => travel.invoice && travel.invoice._id)
            .map((travel) => {
              return {
                label: travel.name,
                destination:
                  '/invoice/' + travel.invoice._id + '/' + this.$i18n.locale,
              };
            })
        "
        :title="$t('agency.invoice')"
        :color="{
          background: $store.getters.color.color2,
          font: $store.getters.color.fontColor,
          linkColor: $store.getters.color.color3,
        }"
        :minWidth="screenMinWidth(1000)"
        class="invoice-mini-search"
        v-if="
          this.travels &&
          (this.$store.getters.userType === 'travelAssistEmployee' ||
            this.$store.getters.userId == this.administrator._id ||
            this.employees
              .map((employee) => employee._id)
              .includes(this.$store.getters.userId))
        "
      ></mini-search>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import Verte from "verte";
import "verte/dist/verte.css";
import miniSearch from "./miniSearch.vue";

export default {
  name: "AgencyDisplay",
  data() {
    return {
      edit: false,
      name: "",
      email: "",
      contractStartDate: "",
      administrator: {},
      clients: [],
      employees: [],
      travels: [],
      phone: "",
      website: "",
      pairedApp: {},
      affiliation: "",
      emergencyNumber: "",
      agencyPic: "",
      contractPassword: "",
      color: {
        color1: "#000000",
        color2: "#000000",
        color3: "#000000",
        fontColor: "#FFFFFF",
      },
      contractSigned: false,
      TAValidation: false,
      contractInformationFilled: false,
      metric: "",
    };
  },
  components: { Verte, miniSearch },
  async created() {
    this.getInformation();
    //this.metric = await HandleApi.getHandlingMetrics();
  },
  computed: {
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      return false;
    },
    buildClientsSearch: function () {
      let temp = [];
      this.clients.forEach((element) => {
        temp.push({
          label:
            this.capitalizeFirstLetter(element.name) +
            " " +
            this.capitalizeFirstLetter(element.surname),
          destination: "/client/" + element._id,
        });
      });
      return temp;
    },
  },
  methods: {
    screenMinWidth(x) {
      return Math.min(x, window.screen.width - 20);
    },
    capitalizeFirstLetter: function (string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      return "";
    },
    niceDate: function (dat) {
      return (
        dat.getFullYear() + "-" + (dat.getMonth() + 1) + "-" + dat.getDate()
      );
    },
    getInformation: async function () {
      try {
        let information = {};
        if (this.$store.getters.userType == "contactCenterAgent") {
          information = await HandleApi.getAgencyInformationMinified(
            this.$route.params.id
          );
          information = information.agency;
        } else {
          information = await HandleApi.getAgencyInformation(
            this.$route.params.id
          );
        }
        this.name = this.capitalizeFirstLetter(information.name)
          .replace(/&amp;/gi, "&")
          .replace(/&#x27;/gi, "'");
        this.email = information.email;
        this.emergencyNumber = information.emergencyNumber;
        if (information.contractStartDate) {
          this.contractStartDate = this.niceDate(
            new Date(information.contractStartDate)
          );
        }
        this.pairedApp = information.pairedApp;
        this.administrator = information.administrator;
        this.clients = information.clients;
        this.color = information.color;
        this.employees = information.employees;
        this.travels = information.travels;
        if (information.hasOwnProperty("phone")) {
          this.phone = information.phone;
        }
        if (information.hasOwnProperty("agencyPic")) {
          this.agencyPic = information.agencyPic;
        }
        if (information.hasOwnProperty("website")) {
          this.website = information.website;
        }
        if (information.hasOwnProperty("affiliation")) {
          this.affiliation = information.affiliation;
        }
        if (information.hasOwnProperty("contractSigned")) {
          this.contractSigned = information.contractSigned;
        }
        if (information.hasOwnProperty("contractPassword")) {
          this.contractPassword = information.contractPassword;
        }
        if (information.hasOwnProperty("TAValidation")) {
          this.TAValidation = information.TAValidation;
        }
        if (information.contractInfo) {
          if (information.contractInfo.hasOwnProperty("informationFilled")) {
            this.contractInformationFilled =
              information.contractInfo.informationFilled;
          }
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
    buildClientLink(clientId) {
      return "/client/" + clientId;
    },
    buildEmployeeLink(employeeId) {
      return "/employee/" + employeeId;
    },
    getBack: function () {
      this.edit = false;
      this.getInformation();
    },
    toogleEdit: function () {
      this.edit = true;
    },
    submitEdit: async function () {
      try {
        let client_agency = [];
        for (let i = 0; i < this.clients.length; i++) {
          client_agency.push(this.clients[i]._id);
        }
        let employee_agency = [];
        for (let i = 0; i < this.employees.length; i++) {
          employee_agency.push(this.employees[i]._id);
        }
        let newData = {
          name: this.name,
          email: this.email,
          contractStartDate: this.contractStartDate,
          administrator: this.administrator._id,
          clients: client_agency,
          employees: employee_agency,
          phone: this.phone,
          website: this.website,
          agencyPic: this.agencyPic,
          color: this.color,
        };
        await HandleApi.putAgency(this.$route.params.id, newData);
        this.$store.dispatch(
          "push_notification_message",
          this.$t("notification.updated", {
            object: this.$t("notification.agency"),
          })
        );
        this.edit = false;
        this.getInformation();
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
    /*addTelegram: async function () {
      try {
        this.$store.dispatch("load", this.$t("loadMessage.telegramLink"));
        let res = await HandleApi.addTelegram(this.$route.params.id);
        window.open(res.url);
        this.$store.dispatch("unload");
      } catch (err) {
        this.$store.dispatch("unload");
        if (err == "already linked") {
          this.$router.go();
        } else {
          this.$store.dispatch("push_error_message", err);
        }
      }
    },
    checkTelegram: async function () {
      try {
        this.$store.dispatch("load", this.$t("loadMessage.checkTelegram"));
        let res = await HandleApi.checkTelegram(this.$route.params.id);
        this.$store.dispatch("unload");
        if (res.data == "telegram connected") {
          this.$store.dispatch(
            "push_notification_message",
            this.$t("agency.isConnected", { app: "Telegram" })
          );
        }
      } catch (err) {
        this.$store.dispatch("unload");
        if (err.response.data == "telegram not connected") {
          this.$store.dispatch(
            "push_error_message",
            this.$t("agency.notConnected", { app: "Telegram" })
          );
          this.$router.go();
        } else {
          this.$store.dispatch("push_error_message", err.response.data);
        }
      }
    },*/
    uploadAgencyPic(e) {
      const width = 200;
      const height = 200;
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        (img.onload = () => {
          const elem = document.createElement("canvas");
          elem.width = width;
          elem.height = height;
          const ctx = elem.getContext("2d");
          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, height);
          const data = ctx.canvas.toDataURL(img);
          this.agencyPic = data;
        }),
          (reader.onerror = (error) => window.console.log(error));
      };
    },
  },
};
</script>

<style scoped>
.edit-div {
  text-align: center;
  margin-top: 20px;
  line-height: 40px;
}

.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.save-button {
  background-color: #008467;
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.generate-form-link {
  background-color: #9ad3d1;
  color: white;
  font-weight: bold;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 5px;
}

.back-button {
  background-color: rgb(255, 72, 72);
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.display {
  text-align: center;
  margin: 0 auto;
}

.display hr {
  width: 30%;
}

.display h1 {
  display: inline-block;
  margin-right: 10px;
}

.edit-button {
  box-shadow: 2px 2px 2px lightgrey;
  color: white;
  border: 2px solid #00909f;
  font-size: 17px;
  background-color: #00909f;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 7px;
  margin: auto 0;
}

.edit-button:hover {
  text-decoration: underline;
}

a {
  color: #206874;
  text-decoration: none;
  margin-left: 5px;
  margin-right: 5px;
}

a:hover {
  text-decoration: underline;
}

.add-app-agency {
  margin-bottom: 10px;
}

.add-app-agency button {
  box-shadow: 2px 2px 2px lightgrey;
  color: white;
  border: none;
  font-size: 17px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 7px;
  margin: auto 0;
  display: inline-block;
  margin-top: 10px;
}

.add-app-agency button:hover {
  text-decoration: underline;
}

.employee-name-display {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}

.browse-button {
  line-height: 20px;
  float: none;
}

.canvas {
  display: inline-block;
}

.color-grid {
  display: inline-block;
}

.delete-button {
  color: white;
  border: none;
  border-radius: 15px;
  padding: 5px;
  background-color: red;
  width: 25px;
}

.delete-button:hover {
  cursor: pointer;
}
.invoice-mini-search {
  margin-bottom: 10px;
}

.contract-not-signed {
  width: 60%;
  margin: 0 auto;
  background-color: rgb(255, 72, 72);
  border-radius: 0.25em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.contract-not-signed {
  font-weight: bold;
}

.contract-not-signed > a {
  font-size: 110%;
  font-weight: bold;
  margin: 0 10px;
  padding: 5px 15px;
  height: 80%;
  border-radius: 0.25em;
  border: none;
  background-color: #fff;
  cursor: pointer;
  color: black;
}

.contract-not-signed > a:hover {
  background-color: #e0e0e0;
  text-decoration: none;
  color: black;
}
</style>

<style>
.verte svg {
  border: 1px solid lightgrey;
  border-radius: 20px;
}
</style>
