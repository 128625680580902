<template>
  <div
    class="miniSearch-container"
    :style="{ minWidth: minWidth, maxHeight: maxHeight, maxWidth: maxWidth }"
  >
    <div
      class="miniSearch-topbar"
      :style="{
        backgroundColor: color.background,
      }"
    >
      <span :style="{ color: color.font }">{{ title }}</span>
      <div class="searchbar" :style="{ borderColor: color.font }">
        <input
          type="text"
          v-model="search"
          :style="{ backgroundColor: color.background, color: color.font }"
        />
        <button
          type="button"
          @click="search = ''"
          :style="{ backgroundColor: color.background, color: color.font }"
        >
          x
        </button>
      </div>
    </div>
    <div class="results-container">
      <div v-for="element in elementsSearch" :key="element.id">
        <router-link
          v-if="element.destination != ''"
          :to="element.destination"
          :style="{ color: color.linkColor }"
          target="_top"
          >{{ element.label }}</router-link
        >
        <span v-else :style="{ color: color.linkColor }">{{
          element.label
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "miniSearch",
  props: {
    elements: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: { type: String, required: true },
    color: {
      type: Object,
      default: () => {
        return {
          background: "rgb(150, 150, 150)",
          font: "black",
          linkColor: "blue",
        };
      },
    },
    minWidth: { type: String, default: "300px" },
    maxWidth: { type: String, default: "500px" },
    maxHeight: { type: String, default: "300px" },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    elementsSearch: function () {
      return this.elements.filter((x) =>
        x.label.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
.miniSearch-container {
  border: none;
  border-radius: 10px;
  display: inline-block;
}
.miniSearch-topbar {
  border-radius: 10px 10px 0 0;
  padding: 5px;
  text-align: right;
}

.miniSearch-topbar .searchbar {
  display: inline-block;
  margin-right: 0;
  margin-left: auto;
  padding: 5px;
  border: 2px solid black;
}

.miniSearch-topbar .searchbar input {
  border: none;
}

.miniSearch-topbar .searchbar button {
  border: none;
}

.miniSearch-topbar .searchbar button {
  cursor: pointer;
}

.miniSearch-topbar span {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.results-container {
  overflow-y: hide;
  border: 1px solid lightgrey;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 0 10px 10px;
}

.results-container div {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
}
</style>

<style>
.results-container a {
  text-decoration: none;
}

.results-container a:hover {
  text-decoration: underline;
}
</style>
